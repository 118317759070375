.card-product {
  position: relative;
  @include grid-container;
  @include grid-column-gap(var(--spacing-04));

  @include breakpoint('medium') {
    @include grid-columns(grid-repeat(20, 1fr));
  }

  @include breakpoint('large') {
    @include grid-columns(grid-repeat(13, 1fr));
  }

  @include breakpoint('xlarge') {
    @include grid-columns(grid-repeat(12, 1fr));
  }

  @include breakpoint('xxlarge') {
    @include grid-columns(grid-repeat(11, 1fr));
  }
}

.card-product__left {
  @include breakpoint('small') {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
  }

  @include breakpoint('medium') {
    @include grid-column(1, 4);
  }

  @include breakpoint('large') {
    @include grid-column(1, 2);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(1, 2);
  }
}

.card-product__img {
  @include img-container($ratio-1x1);
  background-color: var(--color-background);
}

.card-product__right {
  @include grid-column(1, 8);

  @include breakpoint('medium') {
    @include grid-column(6, 15);
  }

  @include breakpoint('large') {
    @include grid-column(4, 10);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 9);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(4, 8);
  }
}

.card-product__infos {
  min-height: 50px;

  @include breakpoint('small') {
    padding-left: 64px;
  }
}

.card-product__title {
  @include f-heading-03-alt;
}

.card-product__custom-message {
  @include f-body-ui;
  margin-top: var(--spacing-02);
}

.card-product__date {
  @include f-body-ui;
  margin-top: var(--spacing-02);
  color: var(--color-text-alt);

  &::first-letter {
    text-transform: uppercase;
  }

  @include breakpoint('medium+') {
    margin-top: var(--spacing-03);
  }
}

.card-product__price {
  margin-top: var(--spacing-04);
}

.card-product__recap {
  @include f-caption;
  color: var(--color-text-alt);
}

.card-product__subtotal {
  @include f-body-ui;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing-04);
}

.card-product__btns {
  margin-top: var(--spacing-04);

  .link + .link {
    margin-left: var(--spacing-04);
  }
}

/* Actions */

.card-product__actions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: var(--spacing-06);
  margin-bottom: calc(var(--spacing-05) * -1);
}

.card-product__actions-btn {
  margin-bottom: var(--spacing-05);
  margin-right: var(--spacing-06);

  &--wallet {
    display: none;

    &.is-visible {
      display: block;
    }
  }

  @include breakpoint('medium+') {
    &--wallet {
      display: none;
    }
  }
}


/*********************
      Modifiers
*********************/

.card-product--featured {
  .card-product__date {
    margin-top: var(--spacing-02);
  }

  @include breakpoint('medium+') {
    .card-product__date {
      margin-top: var(--spacing-04);
    }
  }

  @include breakpoint('large') {
    @include grid-columns(grid-repeat(20, 1fr));

    .card-product__left {
      @include grid-column(1, 5);
    }

    .card-product__right {
      @include grid-column(7, 14);
    }
  }

  @include breakpoint('xlarge') {
    @include grid-columns(grid-repeat(18, 1fr));

    .card-product__left {
      @include grid-column(1, 4);
    }

    .card-product__right {
      @include grid-column(6, 13);
    }
  }

  @include breakpoint('xxlarge') {
    @include grid-columns(grid-repeat(16, 1fr));

    .card-product__left {
      @include grid-column(1, 4);
    }

    .card-product__right {
      @include grid-column(6, 11);
    }
  }
}

.card-product--condensed {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
  margin: 0;

  .card-product__left {
    width: 88px;
    flex-shrink: 0;
  }

  .card-product__right {
    padding-left: var(--spacing-04);
    flex-grow: 1;
  }

  .card-product__img {
    width: 88px;
    height: 88px;
    background-color: var(--color-background);
  }

  .card-product__title {
    @include f-heading-05;
  }

  .card-product__date {
    @include f-caption;
    margin-top: var(--spacing-01);
  }

  .card-product__subtotal {
    @include f-caption;
    margin-top: var(--spacing-01);
    color: var(--color-text-alt);
  }

  .card-product__price {
    display: none;
  }

  @include breakpoint('small') {
    .card-product__left {
      position: static;
    }

    .card-product__infos {
      padding-left: 0;
    }
  }
}
